@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap);
body {
    margin: 0;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: inherit;
}


::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.actual-content{
    padding-top: 95px !important;
}

.heading{
    text-align: center;
    text-decoration: none;
}

.heading:hover{
    text-align: center;
    text-decoration: none;
}

.competition-link{
    color: #08e8de;
}

.competition-link:hover{
    color: #ed594e;
    text-decoration: none;
}

.school-link{
    color: #08e8de;
}

.school-link:hover{
    color: #ed594e;
    text-decoration: none;
}

.competition-div{
    margin-top: 32.5px;
    margin-left: 2%;
    margin-right: 2%;
}

.sub-heading{
    color: inherit;
}

.school-div{
    margin-top: 32.5px;
    margin-left: 2%;
    margin-right: 2%;
}

.about-inner{
    margin-left: 2%;
    margin-right: 2%;
}

.about-outer{
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 20px;
}

.about-img{
    width: 100%;
}

.about-text{
    margin-top: 5%;
}

@media (min-width: 992px) {
  .about-outer{
    display: grid;
    grid-gap: 2%;
  }
  .about-img{
    width: 300px;
}
  .about-inner{
    grid-row: 1/5;
  }
  .about-body{
    grid-row: 2 / 3;
  }
  .about-text{
    margin-top: 0px;
  }
}

@media (min-width: 1425px) {
  .about-outer {
      margin-left: 10%;
      margin-right: 10%;
  }
  .about-text{
    margin-top: 0px;
  }
}

.about-link{
    color: #08e8de;
}

.about-link:hover{
    color: #ed594e;
    text-decoration: none;
}


.toggle-collapse:hover{
    color: #ed594e !important;
    text-decoration: none;
}

.message-link{
    color: #0c5460;
}

.message-link:hover{
    color: #ed594e;
    text-decoration: none;
}

.homework-link{
    color: #08e8de;
}

.homework-link:hover{
    color: #ed594e;
    text-decoration: none;
}

.homework-div{
    margin-top: 20px;
    margin-left: 2%;
    margin-right: 2%;
}


@media (min-width: 992px) {
  .homework-div{
    margin-left: 10%;
    margin-right: 10%;
   }
  .video{
      margin-left: 10%;
      margin-right: 10%;
  }
}

.video-iframe{
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
}

.video-iframe iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-align-self: center;
            align-self: center;
}

#page-content {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

#sticky-footer {
  -webkit-flex-shrink: none;
          flex-shrink: none;
}

.icon{
    width: 25px;
    height: 25px;
    display: inline-block;
}

.index-link{
    color: #008683;
}

.text-link{
    color: #08e8de;
}

.text-link:hover{
    color: #ed594e;
    text-decoration: none;
}

.index-link:hover{
    color: #ed594e;
    text-decoration: none;
}

.index-about{
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
  }


.creation-card{
  transition: 0.3s;
  box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.25), -1px -1px 10px 5px rgba(0, 0, 0, 0.22);
}

.creations-div{
    margin-top: 40px;
    margin-left: 10%;
    margin-right: 10%;
}

@media (min-width: 768px) {
    .index-about{
        display:none;
    }
    .navbar-item:hover {
        -webkit-transform: scale(1.05, 1.05);
                transform: scale(1.05, 1.05);
        transition: 0.3s;
    }
}

.footer-text-dark{
    color: #FFFFFF80
}

.footer-text-light{
    color: #000000E6;
}

.themeicon{
    border-radius: 50%;
    width: 10px
}

.footer-icons{
    display: inline-block;
}

.home-text {
    text-align: center;
    font-size: 3rem;
}

.home-text-sub{
    text-align: center;
    font-size: 2rem;
}

.home-img{
    margin-bottom: 50px;
    text-align: center;
}

@media (max-width: 768px){
    .about-outer {
        margin-bottom:50px;
    }
    .carousel-caption{
        display:none;
    }
    .creations-div{
        margin-top: 20px;
        margin-left: 5%;
        margin-right: 5%;
    }
    body {
        margin-bottom: 110px !important;
    }
    .footer-icons{
        display: block;
    }
    .home-text {
        font-size: 1.5rem;
    }
    .centerer{
        margin-bottom: 5px;
    }
    
    .home-text-sub{
        font-size: 1rem;
    }
    .home-img{
        text-align: center;
        margin-bottom: 20px
    }
}

.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 0;
    z-index: 1;
  }
  
.fade-exit .fade-exit-active {
    opacity: 0;
    transition: opacity 150ms ease-out;
  }

.content{
    position: absolute;
    top: 95px;
    padding-bottom: 77.5px;
    left: 0;
    right: 0;
}

.particles-js{
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.nav-link{
    font-weight:1000;
}

.homework-text{
    font-size: 20px;
}

.sub-heading{
    margin-bottom: 10px;
}

.school-text{
    margin-top: 5px;
}

.competition{
    margin-top: 5px;
}

.creation-button{
    margin-top: 15px;
}

.centered
{
    vertical-align: middle;
    display: inline-block;
}

.centerer
{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.loading {
    width: 200px;
    height: 200px;
    display: block;
    margin: auto;
    margin-top: 30vh;
}
